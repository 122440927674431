<template>
    <div id="books_comments">
        <div class="history">
            <div class="comments_left">
                <div
                    class="contentfont aqt_lang_mn add_comment"
                    @click="showDefault()"
                >
                    {{ " ᠲᠥᠷᠥᠭᠳᠡᠯ ᠪᠢᠴᠢᠬᠦ " | U2M }}
                    <i class="iconfont iconicon-test"></i>
                </div>
                <div class="contentfont aqt_lang_mn total">
                    <span
                        >{{ "ᠪᠦᠬᠦᠳᠡ " | U2M }}{{ total
                        }}{{ " ᠵᠤᠷᠪᠤᠰ" | U2M }}</span
                    >
                </div>
            </div>
            <p
                v-if="isLoading && !dataList.length"
                class="noData aqt_lang_mn titlefont"
            >
                {{ "ᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠ ᠪᠠᠷᠢᠮᠲᠠ ᠦᠭᠡᠢ" | U2M }}
            </p>
            <div
                class="commentsList"
                v-for="(item, index) in dataList"
                :key="index"
            >
                <div class="personal" v-if="item.usrinfo">
                    <div class="headImg">
                        <img
                            :src="
                                item.usrinfo.wx_img
                                    ? item.usrinfo.wx_img
                                    : logo_icon
                            "
                            alt=""
                        />
                    </div>
                    <div class="infos">
                        <div class="right">
                            <div class="name contentfont aqt_lang_mn">
                                {{
                                    item.usrinfo.nickname
                                        ? item.usrinfo.nickname
                                        : item.usrinfo.tel
                                        ? item.usrinfo.tel
                                        : "" | U2M
                                }}
                            </div>

                            <div
                                class="del contentfont aqt_lang_mn"
                                v-if="item.showDel"
                                @click="showDelalert(item.after_read_id)"
                            >
                                {{ "ᠤᠰᠠᠳᠬᠠᠬᠤ" | U2M }}
                            </div>
                        </div>
                        <!-- <div class="name contentfont aqt_lang_mn">{{item.usrinfo.nickname?item.usrinfo.nickname:item.usrinfo.username?item.usrinfo.username:''|U2M}}</div> -->
                        <div class="time aqt_su">{{ item.created_at }}</div>
                    </div>
                </div>

                <div class="comments_content contentfont aqt_lang_mn">
                    {{ item.content ? item.content : "" | U2M }}
                </div>

                <div class="comments_imgs">
                    <!-- 单图 -->
                    <div class="one_img" v-if="item.imgs.length == 1">
                        <img
                            :src="item.img[0]"
                            alt=""
                            @click="showImagePreview(0, item.img)"
                        />
                    </div>
                    <!-- 二三图 -->
                    <div
                        class="three_img"
                        v-if="item.imgs.length > 1 && item.imgs.length < 4"
                    >
                        <img
                            :src="item1"
                            alt=""
                            @click="showImagePreview(index, item.img)"
                            v-for="(item1, index) in item.img"
                            :key="index"
                        />
                    </div>
                    <!-- 三以上奇数图 -->
                    <div
                        class="odd_img"
                        v-if="item.imgs.length > 3 && item.imgs.length % 2 == 1"
                    >
                        <img
                            class="first"
                            :src="item.img[0]"
                            alt=""
                            @click="showImagePreview(0, item.img)"
                        />
                        <div class="others">
                            <div
                                v-for="(item1, index1) in item.img"
                                :key="index1"
                            >
                                <img
                                    @click="showImagePreview(index1, item.img)"
                                    :src="item1"
                                    alt=""
                                    v-if="index1 > 0"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- 三以上偶数图 -->
                    <div
                        class="odd_img"
                        v-show="
                            item.imgs.length > 2 && item.imgs.length % 2 == 0
                        "
                    >
                        <div class="others">
                            <img
                                :src="item1"
                                @click="showImagePreview(index1, item.img)"
                                alt=""
                                v-for="(item1, index1) in item.img"
                                :key="index1"
                            />
                        </div>
                    </div>

                    <div class="video" v-if="item.video">
                        <video
                            webkit-playsinline
                            :poster="poster"
                            playsinline
                            @click="pla($event, '2')"
                            id="video"
                            class="video_img"
                        >
                            <source
                                webkit-playsinline
                                playsinline
                                :src="item.video"
                                type="video/mp4"
                            />
                        </video>
                        <i
                            @click="pla($event, '1')"
                            class="PlayButton iconbofang iconfont"
                        ></i>
                    </div>
                </div>

                <div class="comment_address" v-show="item.address">
                    <i class="iconfont icondizhi"></i
                    ><span class="address contentfont aqt_lang_mn">{{
                        item.address
                    }}</span>
                </div>
            </div>
        </div>

        <!-- <div class="commentsList">
    <div class="personal">
      <div class="infos">
        <div class="name contentfont aqt_lang_mn">{{'ᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠ'|U2M}}</div>
        <div class="time aqt_su">2020-2-20</div>
      </div>
    </div>
    <div class="comments_content contentfont aqt_lang_mn">
      {{'ᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠ'|U2M}}
    </div>
    <div class="comments_imgs">
      <div class="video">
        <video webkit-playsinline :poster='poster' playsinline @click="pla($event, '2')" id="video" class="video_img">
          <source webkit-playsinline playsinline src="http://s2.pstatp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo.mp4" type="video/mp4" />
        </video>
        <i @click="pla($event,'1')" class="PlayButton iconbofang iconfont"></i>
      </div>
    </div>
  </div> -->
        <Lay-action-sheet
            :IsShow="confirm.IsShow"
            @select1="select1"
            @select2="select2"
            :option1="option1"
            :option2="option2"
            @isClose="confirm.IsShow = false"
        ></Lay-action-sheet>
        <lay-confirm
            :IsShow="confirm1.IsShow"
            :message="confirm1.message"
            @isClose="onClose"
            @on-ok="onOk"
        ></lay-confirm>
    </div>
</template>

<script>
export default {
    props: {
        data: {},
    },
    //监控data中的数据变化
    watch: {},
    components: {},
    data() {
        return {
            isLoading: false,
            confirm1: {
                IsShow: false,
                message: "",
            },
            member_id: JSON.parse(localStorage.getItem("userInfoMemberId"))
                ? JSON.parse(localStorage.getItem("userInfoMemberId")).member_id
                : "",
            total: null,
            option1: "ᠰᠢᠨ᠎ᠡ ᠵᠢᠷᠤᠭ ᠰᠡᠭᠦᠳᠡᠷ ",
            option2: "ᠰᠢᠨ᠎ᠡ ᠲᠥᠷᠰᠦ ᠪᠢᠴᠢᠯᠭᠡ  ",
            confirm: {
                IsShow: false,
                message: "",
            },
            logo_icon: "",
            poster: null,
            pd: null,
            pds: true,
            isLoads: false,
            isMounted: false,
            videoWidth: 200,
            videoHeight: 150,
            dataList: [],
            condition: {
                library_id: JSON.parse(localStorage.getItem("theme_color"))
                    .library_id, //馆藏id  48内蒙古财经大学图书馆
                book_id: "",
                type: 1, //1.纸质2电子
                page: 1,
            },
            after_read_id: [],
        };
    },
    methods: {
        onClose() {
            this.confirm1.IsShow = false;
            this.after_read_id = [];
        },
        showDelalert(id) {
            const _this = this;
            console.log(_this.confirm1.IsShow, "sss");

            this.after_read_id.push(id);
            _this.confirm1.message = "ᠲᠠ ᠤᠰᠠᠳᠬᠠᠨ᠎ᠠ  ᠤᠤ ？";
            _this.confirm1.IsShow = true;
        },
        onOk() {
            this.confirm1.IsShow = false;
            this.del();
        },
        del() {
            this.$http
                .get(
                    "api/web/v1/afterread/del/" +
                        JSON.stringify(this.after_read_id)
                )
                .then((val) => {
                    this.after_read_id = [];
                    this.condition.page = 1;
                    this.dataList = [];
                    this.queryData();
                    console.log(val, "ssssssss");
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        select2() {
            const that = this;
            that.$router.push({
                path: "/New_comments",
                query: {
                    mark: "2",
                    book_id: this.data.book_id,
                },
            });
        },
        select1() {
            const that = this;
            that.$router.push({
                path: "/New_comments",
                query: {
                    mark: "1",
                    book_id: that.data.book_id,
                },
            });
        },
        showImagePreview(index, item) {
            this.initialIndex = index;
            const params = {
                $props: {
                    imgs: item, //存当前图片的数组，如果是渲染的每一项，通过形参传进来
                    initialIndex: "initialIndex", //把图片数组循环后，图片的索引
                    loop: true,
                },
                $events: {
                    change: (i) => {
                        // 必须更新 initialIndex
                        this.initialIndex = i;
                    },
                },
            };
            this.$createImagePreview({
                ...params,
            }).show();
        },
        /*****************************************************************
         *
         * 获取列表数据
         *
         *****************************************************************/
        queryData() {
            const _this = this;
            this.condition.book_id = this.$route.query.id;
            _this.showToastMask(true);
            _this.isLoads = false;
            _this.isLoading = false;
            _this.$http
                .get("api/web/v1/afterread/index", {
                    params: _this.condition,
                })
                .then((val) => {
                    this.total = val.pages.total;
                    let oldpage = _this.condition.page;
                    let last_page = val.pages.last_page;
                    _this.condition.page = val.pages.current_page;
                    if (_this.condition.page == last_page) {
                        // 表示已是最后一页
                        _this.condition.page = last_page;
                        _this.isLoads = false;
                    } else {
                        _this.condition.page = Number(_this.condition.page) + 1;
                        _this.isLoads = true;
                    }
                    if (val.data.length) {
                        if (oldpage > val.pages.current_page) {
                            return;
                        }
                        // let ff = _this.processData(val.data)

                        val.data.forEach((item, index) => {
                            if (item.usrinfo) {
                                if (this.member_id) {
                                    if (
                                        item.usrinfo.member_id == this.member_id
                                    ) {
                                        item.showDel = true;
                                    } else {
                                        item.showDel = false;
                                    }
                                }
                            }

                            item.img = [];
                            if (item.usrinfo) {
                                if (item.usrinfo.tel) {
                                    const reg = /^(\d{3})\d{4}(\d{4})$/;
                                    item.usrinfo.tel = item.usrinfo.tel.replace(
                                        reg,
                                        "$1****$2"
                                    );
                                }
                            }

                            item.imgs.forEach((it, index1) => {
                                item.img.push(it.url);
                            });
                            _this.dataList.push(item);
                        });
                    } else {
                        _this.dataList = [];
                        val.data.forEach((item) => {
                            item.img = [];
                            item.imgs.forEach((it, index1) => {
                                item.img.push(it.url);
                            });

                            _this.dataList.push(item);
                        });
                    }
                    _this.showToastMask(false);
                    _this.isLoading = true;
                })
                .catch((err) => {
                    _this.showToastMask(false);
                    _this.isLoads = false;
                    _this.isLoading = true;
                });
        },

        showDefault() {
            const that = this;
            if (this.member_id == "" || !this.member_id) {
                this.$router.push({
                    path: "/login",
                    query: {
                        lastPath: this.$route.fullPath,
                    },
                });
                return;
            }
            this.confirm.IsShow = true;
        },
        news() {
            var that = this;

            plus.nativeUI.actionSheet(
                {
                    cancel: "取消",
                    buttons: [
                        {
                            title: "发布照片",
                        },
                        {
                            title: "发布视频",
                        },
                    ],
                    popover: {
                        top: 10,
                        left: 10,
                        width: 200,
                        height: 200,
                    },
                },
                function (e) {
                    if (e.index == "1") {
                        that.$router.push({
                            path: "/ReleaseDynamics",
                            query: {
                                mark: "1",
                                book_id: this.data.book_id,
                            },
                        });
                    } else if (e.index == "2") {
                        that.$router.push({
                            path: "/ReleaseDynamics",
                            query: {
                                mark: "2",
                                book_id: this.data.book_id,
                            },
                        });
                    }
                }
            );
        },
        handleUpload(files) {
            console.log(this.$refs.file.files[0]);
            // 创建FormData对象
            // 将得到的文件流添加到FormData对象
            let formData = new FormData();
            let fs = this.$refs.file.files[0];
            // 将得到的文件流添加到FormData对象
            formData.append("file", fs);
            this.$http
                .post("app/commons/upload_file", formData)
                .then((val) => {
                    if (val.success == true) {
                        this.video.videourl = val.data;
                        this.tips("", "上传成功！", "correct");
                    }
                    console.log(val, "文件");
                })
                .catch((err) => {
                    err;
                });
        },
        show() {
            this.$refs.myPopup.show();
        },
        /************************************************************
         *
         * 关闭弹出框
         *
         ************************************************************/
        hide() {
            this.$refs.myPopup.hide();
        },
        start(e) {
            console.log(e);
            var video = document.getElementById("video");
            video.play();
            // video.pause()
        },
        //截取视频第一帧
        findvideocover() {
            // 创建一个video节点
            var video = document.getElementById("video");
            const that = this;
            video.addEventListener("loadeddata", function (e) {
                setTimeout(() => {
                    var canvas = document.createElement("canvas");
                    var ctx = canvas.getContext("2d");

                    canvas.width = this.videoWidth;
                    canvas.height = this.videoHeight;
                    let width = this.videoWidth;
                    let height = this.videoHeight;
                    ctx.drawImage(this, 0, 0, width, height);
                    var src = canvas.toDataURL("image/png");
                    that.poster = src;
                    // console.log(that.poster);
                }, 1000);

                // var currentTime = this.currentTime
                // duration = this.duration
                // var fps = duration / 30
            });
        },
        /****************************************************
         *
         *单击视频
         *
         ****************************************************/
        pla(e, num) {
            var that = this;
            var ele;
            if (num == "1") {
                ele = e.target.parentElement.children[0];
            } else {
                ele = e.target;
            }
            if (this.iosversion.name == "iOS") {
                e.preventDefault();
                ele.play();
                ele.addEventListener(
                    "touchstart",
                    function () {
                        clearTimeout(window.timeOutEvent); //清除定时器
                        window.timeOutEvent = 0;
                        window.timeOutEvent = setTimeout(function () {
                            plus.nativeUI.actionSheet(
                                {
                                    cancel: "取消",
                                    buttons: [
                                        {
                                            title: "保存到本地",
                                        },
                                    ],
                                },
                                function (e) {
                                    if (e.index == "1") {
                                        that.xiazaishiping(ele.children[0].src);
                                    }
                                }
                            );
                        }, 500); //这里设置定时
                    },
                    false
                );
                ele.addEventListener(
                    "touchmove",
                    function () {
                        clearTimeout(window.timeOutEvent); //清除定时器
                    },
                    false
                );
                ele.addEventListener(
                    "touchend",
                    function () {
                        clearTimeout(window.timeOutEvent); //清除定时器
                    },
                    false
                );
            } else {
                that.DocumenDblClick(ele);
            }
        },
        /****************************************************
         *
         *单击视频
         *
         ****************************************************/
        DocumenDblClick(ele) {
            if (this.isFullScreen()) {
                this.exitFullscreen(ele);
            } else {
                this.full(ele);
            }
        },
        /****************************************************
         *
         *进入全屏
         *
         ****************************************************/
        full(ele) {
            var that = this;
            if (ele.requestFullscreen) {
                ele.requestFullscreen()
                    .then((val) => {
                        ele.play();
                    })
                    .catch((err) => {
                        console.log("报错");
                    });
            } else if (ele.mozRequestFullScreen) {
                ele.mozRequestFullScreen();
                ele.play();
            } else if (ele.webkitRequestFullscreen) {
                ele.webkitRequestFullscreen();
                ele.play();
            } else if (ele.msRequestFullscreen) {
                ele.msRequestFullscreen();
                ele.play();
            }
            ele.addEventListener(
                "touchstart",
                function () {
                    clearTimeout(window.timeOutEvent); //清除定时器
                    window.timeOutEvent = 0;
                    window.timeOutEvent = setTimeout(function () {
                        plus.nativeUI.actionSheet(
                            {
                                cancel: "取消",
                                buttons: [
                                    {
                                        title: "保存到本地",
                                    },
                                ],
                            },
                            function (e) {
                                if (e.index == "1") {
                                    that.xiazaishiping(ele.children[0].src);
                                }
                            }
                        );
                    }, 500); //这里设置定时
                },
                false
            );
            ele.addEventListener(
                "touchmove",
                function () {
                    clearTimeout(window.timeOutEvent); //清除定时器
                },
                false
            );
            ele.addEventListener(
                "touchend",
                function () {
                    clearTimeout(window.timeOutEvent); //清除定时器
                },
                false
            );
        },
        /****************************************************
         *
         *退出全屏
         *
         ****************************************************/
        exitFullscreen(ele) {
            console.log(ele);

            if (document.exitFullScreen) {
                document.exitFullScreen();
                ele.pause();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
                ele.pause();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
                ele.pause();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
                ele.pause();
            }
        },
        /****************************************************
         *
         *判断当前是否全屏
         *
         ****************************************************/
        isFullScreen() {
            return !!(
                document.fullscreen ||
                document.mozFullScreen ||
                document.webkitIsFullScreen ||
                document.webkitFullScreen ||
                document.msFullScreen
            );
        },
    },
    created() {
        this.logo_icon = JSON.parse(
            localStorage.getItem("theme_color")
        ).logo_icon;
    },
    mounted() {
        // this.findvideocover()
        console.log(this.data, "dayass");
        const _this = this;
        console.log(document.getElementsByClassName("address")[0]);

        InfiniteScroll(
            document.getElementsByClassName("history")[0],
            function () {
                if (_this.isLoads) {
                    _this.queryData();
                    return;
                }
            },
            _this.loadDistance
        );
        _this.isMounted = true;
        _this.queryData();
    },
};
</script>

<style lang="less" scoped>
// .cube-safe-area-pb {
//   transform: rotate(-90deg) !important;
// }

// .cube-action-sheet-content {
//   transform: rotate(-180deg) !important;

// }

// .cube-action-sheet-cancel {
//   transform: rotate(-180deg) !important;

// }

// .cube-popup-content {
//   position: absolute;
//   top: -115px;
//   left: 106px;
//   width: 100%;
//   box-sizing: border-box;
// }

#books_comments {
    .cube-popup-content {
        background-color: #fff;
        padding: 5px;
        position: relative;
        width: 80% !important;
        height: 82.5% !important;
    }

    .history {
        width: 100%;
        height: 100%;
        overflow-x: scroll;
        display: flex;
    }

    .noData {
        color: #666;
        display: block;
        margin-left: 120px;
        font-size: 16px;
    }

    height: calc(100% - 25rem);
    overflow: auto;
    display: flex;

    .cube-popup {
        z-index: 99999 !important;
    }

    .cube-action-sheet-panel {
        transform: rotate(90deg) !important;
    }

    .cube-popup-content {
        margin-top: 30px;
        border-radius: 15px;
        background-color: #fff;
        padding: 15px !important;
        position: relative;
        width: 96%;
        height: 82.5%;

        .delete_img {
            z-index: 999999;
            width: 25px;
            height: 25px;
            position: absolute;
            right: 15px;
            top: 15px;
        }
    }

    textarea {
        width: 100%;
        height: 200px;
        margin-top: 40px;
        border: none;
        outline: none;
        font-size: 16px;
        padding: 15px;
    }

    .popup_video {
        height: 70px;
        width: 70px;
        background-color: #fff;

        .file {
            margin-top: 30px;
            height: 70px;
            width: 70px;
            left: 50%;
            transform: translateX(-50%);
            position: relative;
            display: inline-block;
            background: #f6f7f9;
            overflow: hidden;
            text-decoration: none;
            text-indent: 0;

            .upLoad {
                height: 100%;
                width: 100%;
            }
        }

        .iconicon-test {
            font-size: 30px;
            color: var(--themeColor);
        }

        .iconicon-test:before {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .file input {
            position: absolute;
            font-size: 15px;
            right: 0;
            top: 0;
            opacity: 0;
        }
    }

    .comments_left {
      height: 100%;
    }

    .total {
        // height: 20%;
        padding: 10px;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0 10px;
    }

    .add_comment {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 40%;
        padding: 10px;
        font-size: 18px;
        background-color: var(--themeColor);
        color: #fff;
        border-radius: 5px;
        margin: 0 10px;

        span {
            margin-top: 20px;
        }

        i {
            margin-top: 20px;
            font-size: 20px;
        }
    }

    .video {
        position: relative;
        // position:relative;

        // .stop{
        //   position:absolute;
        //   width:50px;
        //   height:50px;
        //   left:46%;
        //   top:31%;
        // }
    }

    video::-webkit-media-controls {
        display: none !important;
    }

    video {
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
    }

    .video_img {
        height: 200px;
        width: 200px;
    }

    .time {
        margin: 0;
    }

    .PlayButton {
        font-size: 28px;
        position: absolute;
        color: #ccc;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .video-player {
        width: 200px;
        height: 200px;
    }

    .video-js .vjs-icon-placeholder {
        width: 100%;
        height: 100%;
        display: block;
    }

    .commentsList {
        padding: 0 10px;
        display: flex;
        border-right: 1px solid #ccc;

        .personal {
            width: 60px;
            height: 100%;
            display: flex;
            flex-direction: column;

            .headImg {
                img {
                    display: block;
                    margin: 0 auto;
                    width: 50px;
                    height: 50px;
                    border-radius: 10px;
                    margin-bottom: 10px;
                }
            }

            .infos {
                display: flex;
                justify-content: space-around;
                height: 100%;

                .name {
                    font-size: 16px;
                }

                .right {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }

                .time {
                    font-size: 14px;
                }

                .del {
                    font-size: 14px;
                    margin-top: 40px;
                    color: var(--themeColor);
                    padding-bottom: 30px;
                    z-index: 9999;
                }
            }
        }

        .comment_address {
            color: #666;
            margin-left: 10px;
            display: flex;
            // min-width: 3rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            i {
            }

            span {
                font-size: 12px;
                line-height: 1.6rem;
            }
        }

        .comments_content {
            color: #333;
            white-space: wrap;
            margin-left: 5px;
            height: calc(100%);
            font-size: 18px;
        }

        .comments_imgs {
            margin-left: 10px;

            .one_img {
                img {
                    // width: 170px;
                    max-height: 220px;
                }
            }

            .three_img {
                display: flex;
                flex-direction: column;
                height: 100%;

                img {
                    margin-top: 5px;
                    height: 25%;
                    width: 170px;
                }

                img:nth-child(1) {
                    border-radius: 10px 10px 0 0;
                }

                img:last-child {
                    border-radius: 0 0 10px 10px;
                }
            }

            .odd_img {
                display: flex;
                flex-direction: column;
                border-radius: 10px;
                overflow: hidden;
                height: 100%;

                .first {
                    max-height: 50px;
                    width: 180px;
                    margin-bottom: 2px;
                }

                .others {
                    width: 180px;
                    display: flex;
                    flex-wrap: wrap;
                    box-sizing: content-box;

                    img {
                        width: 88px;
                        height: 50px;
                    }

                    img:nth-child(odd) {
                        margin-right: 2px;
                        margin-bottom: 2px;
                    }

                    img:nth-child(even) {
                        //  margin-left: 2px;
                        margin-bottom: 2px;
                    }
                }
            }
        }
    }
}
</style>
